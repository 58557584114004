import React from 'react';

const QForm = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '1000px',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <iframe
        src="https://tayori.com/form/4e524eb9e88032d1801ba11a30c67e59119b8f52/"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default QForm;
